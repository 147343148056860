import Vue from 'vue';
import VueRouter from 'vue-router';
import Layout from '@/layout/Layout.vue';
import LayoutHome from '@/layout/LayoutHome.vue';
import Home from '../views/Home.vue';
import Main from '../views/Main.vue';
//import BrandManagement from "@/views/BrandManagement.vue";
import ShopManagement from '@/views/ShopManagement.vue';
import RequestManagement from '@/views/RequestManagement.vue';
import MusicManagement from '@/views/MusicManagement.vue';
import Notice from '@/views/notice/Notice.vue';
import Message from '@/views/notice/Message.vue';
import CS from '@/views/cs/Cs.vue';
import FAQ from '@/views/cs/Faq.vue';
import Question from '@/views/cs/Question.vue';
import Login from '@/views/Login.vue';
import SignUp from '@/views/signUp/SignUp.vue';
import SignUpFinished from '@/views/signUp/SignUpFinished.vue';
import FindId from '@/views/find/FindId.vue';
import FindPass from '@/views/find/FindPass.vue';
import ChangePassword from '@/views/find/ChangePassword.vue';
import ChangePassFinished from '@/views/find/ChangePassFinished.vue';
import FindIdFinished from '@/views/find/FindIdFinished.vue';
import LoginLayout from '@/layout/LoginLayout.vue';
import Policy from '@/views/policy/Policy.vue';
import Service from '@/views/policy/Service.vue';
import Privacy from '@/views/policy/Privacy.vue';
//import Marketing from "@/views/policy/Marketing.vue";
// import ToggleButton from 'vue-js-toggle-button';
import Switch from '@/views/Switch.vue';
import NoticeList from '@/views/notice/noticeListView.vue';
//.use(ToggleButton).component('ToggleSwitch', Switch);
Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: '/brand/home',
    component: LayoutHome,
    children: [
      {
        path: '/brand/home',
        name: 'brand/home',
        component: Main,
        props: true,
      },
      {
        path: '/brand/stplat',
        redirect: '/brand/stplat/service',
        name: 'HomePolicy',
        component: () => import('@/views/MainPolicy.vue'),
        children: [
          {
            path: '/brand/stplat/service',
            name: 'HomeService',
            component: () => import('@/views/policy/Service.vue'),
          },
          {
            path: '/brand/stplat/privacy',
            name: 'HomePrivacy',
            component: () => import('@/views/policy/Privacy.vue'),
          },
        ],
      },
      {
        path: '/brand/noticelist',
        name: 'BrandNoticeList',
        component: NoticeList,
      },
      {
        path: '/brand/noticedetail/:id',
        name: 'NoticeDetailView',
        component: () => import('@/views/notice/noticeDetailView.vue'),
      },
    ],
  },
  {
    path: '/brand/login',
    component: LoginLayout,
    children: [
      {
        path: '/brand/login',
        name: 'brand/login',
        component: Login,
        props: true,
      },
      {
        path: '/brand/signUp',
        name: 'brand/sineUp',
        component: SignUp,
      },
      {
        path: '/brand/signUpFinished',
        name: 'brand/sineUpFinished',
        component: SignUpFinished,
        props: true,
      },
      {
        path: '/brand/findId',
        name: 'brand/findId',
        component: FindId,
      },
      {
        path: '/brand/findPass',
        name: 'brand/findPass',
        component: FindPass,
      },
      {
        path: '/brand/changePassword',
        name: 'brand/changePassword',
        component: ChangePassword,
        props: true,
      },
      {
        path: '/brand/changePassFinished',
        name: 'brand/changePassFinished',
        component: ChangePassFinished,
      },
      {
        path: '/brand/findIdFinished',
        name: 'brand/findIdFinished',
        component: FindIdFinished,
        props: true,
      },
    ],
  },
  {
    path: '/brand/main',
    component: Layout,
    children: [
      {
        path: '/brand/main',
        name: 'brand',
        component: Home,
        meta: { isLogin: true },
      },
      /*
            {
              path: "/brand/brandManagement",
              name: "brand/brandManagement",
              component: BrandManagement
            },
            */
      {
        path: '/brand/shopManagement',
        name: 'brand/shopManagement',
        component: ShopManagement,
        props: true,
        meta: { isLogin: true },
      },
      {
        path: '/brand/requestManagement',
        name: 'brand/requestManagemant',
        component: RequestManagement,
        props: true,
        meta: { isLogin: true },
      },
      {
        path: '/brand/musicManagement',
        name: 'brand/musicManagement',
        component: MusicManagement,
        props: true,
        meta: { isLogin: true },
      },
      {
        path: '/brand/notice',
        name: 'brand/notice',
        component: Notice,
        meta: { isLogin: true },
      },
      {
        path: '/brand/notice/message',
        name: 'brand/notice/message',
        component: Message,
        props: true,
        meta: { isLogin: true },
      },
      {
        path: '/brand/cs',
        redirect: '/brand/cs/faq',
        name: 'brand/CS',
        component: CS,
        meta: { isLogin: true },
        children: [
          {
            path: '/brand/cs/faq',
            name: 'brand/FAQ',
            component: FAQ,
            meta: { isLogin: true },
          },
          {
            path: '/brand/cs/question',
            name: 'brand/Question',
            component: Question,
            meta: { isLogin: true },
          },
        ],
      },
      {
        path: '/brand/policy',
        redirect: '/brand/policy/service',
        component: Policy,
        meta: { isLogin: true },
        children: [
          {
            path: '/brand/policy/service',
            name: 'brand/policy/service',
            component: Service,
            meta: { isLogin: true },
          },
          {
            path: '/brand/policy/privacy',
            name: 'brand/policy/privacy',
            component: Privacy,
            meta: { isLogin: true },
          },
          /*{
                      path: '/brand/policy/marketing',
                      name: 'brand/policy/marketing',
                      component: Marketing
                    },*/
        ],
      },
      {
        path: '/brand/switch',
        name: 'brand/switch',
        component: Switch,
        meta: { isLogin: true },
      },
    ],
  },
  {
    path: '/brand/auth/:toMail/:authCode',
    name: 'CorpEmailAuth',
    component: () => import('@/views/EmailAuth.vue'),
  },
  {
    path: '/brand/nice',
    name: 'CorpNiceAuth',
    component: () => import('@/views/NiceAuth.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.afterEach(to => {
  const title = to.meta.title === undefined ? 'APLAYZ' : to.meta.title;
  Vue.nextTick(() => {
    document.title = title;
  });
});

export default router;
