import axios from 'axios';

// File Upload
const uploadChosenFile = async form => {
  return await axios.post('/api/brand/uploadChosenFile', form, {
    header: { 'Content-Type': 'multipart/form-data' },
  });
};

const registMySoundByFile = async ({ corpId, brandId, soundTitle, tempNo, playtime }) => {
  return await axios.post('/api/brand/registMySoundByFile', { corpId, brandId, soundTitle, tempNo, playtime });
};

// TTS

const listenTextPreviously = async ({ corpId, brandId, textOfMessage, voiceType, voiceName }) => {
  return await axios.post('/api/brand/listenTextPreviously', {
    corpId,
    brandId,
    textOfMessage,
    voiceType,
    voiceName,
  });
};

const getTTSCount = async ({ corpId, brandId }) => {
  return await axios.post('/api/mysound/getTTSCount', {
    corpId,
    brandId,
  });
};
const downloadTTSFile = async ({ corpId, brandId, tempNo }) => {
  return await axios.post('/api/brand/downloadTTSFile', { corpId, brandId, tempNo });
};

const registMySoundByText = async ({ corpId, brandId, soundTitle, tempNo }) => {
  return await axios.post('/api/brand/registMySoundByText', { corpId, brandId, soundTitle, tempNo });
};
export { uploadChosenFile, registMySoundByFile, listenTextPreviously, getTTSCount, downloadTTSFile, registMySoundByText };
