const simplealert = {
  namespaced: true,
  state: () => ({
    isShow: false,
    isFinal: false,
    isYn: false,
    isFunc: false,
    bodyText: '알럿입니다.',
    confirmFunc: () => {},
  }),
  getters: {
    getIsShow(state) {
      return state.isShow;
    },
    getBodyText(state) {
      return state.bodyText;
    },
    getIsFinal(state) {
      return state.isFinal;
    },
    getIsYn(state) {
      return state.isYn;
    },
    getIsFunc(state) {
      return state.isFunc;
    },

    // getConfirmFunc(state) {
    //  state.isYn = false;
    //  return state.confirmFunc();
    // }
  },
  mutations: {
    open(state, val) {
      state.bodyText = val;
      state.isShow = true;
    },
    ynOpen(state, val) {
      state.bodyText = val;
      state.isShow = true;
      state.isYn = true;
    },
    openFinal(state, val) {
      state.bodyText = val;
      state.isShow = true;
      state.isFinal = true;
    },
    close(state) {
      state.bodyText = '';
      state.isShow = false;
      state.isFinal = false;
      state.isYn = false;
      state.isFunc = false;
    },
    setIsFunc(state) {
      state.isFunc = true;
    },
    // setConfirmFunc(state, val) {
    //  state.confirmFunc = val;
    // }
  },
  actions: {},
};
export { simplealert };
