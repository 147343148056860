<template>
  <div class="alert">
    <div class="alert_box">
      <h3 v-html="contents"></h3>
      <div class="delet_finish_btn">
        <p id="color_txt" @click="onClickCloseModal">확인</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Alert',
  emits: ['close-alert'],
  props: {
    contents: {
      type: String,
      default: '',
      required: false,
    },
  },
  data() {
    return {};
  },
  methods: {
    onClickCloseModal() {
      this.$emit('close-alert');
    },
  },
};
</script>
<style src="@/assets/css/font.css"></style>
<style src="@/assets/css/content.css"></style>
<style scoped>
h3 {
  width: 100%;
  padding: 0 0 24px;
  color: rgba(255, 255, 255, 0.87);
  font-size: 16px;
  font-weight: 300;
  text-align: center;
  line-height: 1.3;
  word-break: keep-all;
}
p {
  padding: 0;
  text-align: center;
  height: 60px;
  line-height: 60px;
  color: #ff2d55;
  border-top: 1px solid rgba(255, 255, 255, 0.08);
  cursor: pointer;
}
</style>
