import axios from 'axios';

/**
 * @description 파일 업로드 Binary
 * @param {*} formData
 * @returns Promise
 */
const uploadChosenFileApi = async (formData = new FormData()) => {
  return await axios.post(`/api/brand/uploadChosenVoiceRecFile`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

/**
 * @description 파일명 입력
 * @param {*} corpId 법인ID
 * @param {*} brandId 브랜드ID
 * @param {*} soundTitle 파일명
 * @param {*} fileOnServer 파일경로
 * @param {*} playtime 재생시간
 * @returns
 */
const registMySoundByFileApi = async (corpId = null, brandId = null, soundTitle = null, tempNo = null, playtime = 0) => {
  return await axios.post(`/api/brand/registMySoundByVoiceRecFile`, {
    corpId,
    brandId,
    soundTitle,
    tempNo,
    playtime,
  });
};
export { uploadChosenFileApi, registMySoundByFileApi };
