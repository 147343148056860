<template>
  <div class="modal">
    <Alert v-if="isAlert" :contents="alertText" @close-alert="onCloseAlert" />
    <Confirm
      v-if="isBackConfirmModal"
      :contents="'초기화 버튼 클릭 시<br/> 이미 녹음하신 파일은 삭제됩니다.<br/>녹음을 시작하시겠습니까?'"
      @cancel-click="onClickBackConfirmModalCancel"
      @confirm-click="onClickBackConfirmModalConfirm" />
    <audio id="voideReocrderAudio" hidden></audio>
    <div class="modal_box voicerecording_box">
      <div class="voicerecording_title_wrap dp_flex flex_space_between align_center">
        <div>
          <img @click="onClickBackStep" class="less_than cursor" src="/media/img/vector.png" alt="닫기" />
          <span class="voicerecording_title">음성녹음</span>
        </div>
        <div>
          <img class="cursor" @click="onClickClose" src="/media/images/icon_close_24.svg" alt="닫기" />
        </div>
      </div>
      <!-- 녹음 전, 녹음 중, 녹음 완료-->
      <p class="voicerecording_desc" v-if="recordingStatus === 1 || recordingStatus === 2 || recordingStatus === 3">
        {{ recorderText }} <br />
        <span class="voicerecording_desc_sub" :class="{ ready: recordingStatus === 1 }">(최대 1분)</span>
      </p>
      <div class="voicerecording_progress" v-if="recordingStatus === 1 || recordingStatus === 2 || recordingStatus === 3">
        <!-- 녹음 전-->
        <ul id="voiceRecording" class="voicerecording_before flex_space_between start" v-if="recordingStatus === 1">
          <li class="recording_bar" v-for="(_, index) in barLength" :key="'bar' + index" :id="'bar' + (index + 1)"></li>
        </ul>
        <!--, 녹음 중 -->
        <canvas v-if="recordingStatus === 2" id="audioAnalyser" class="audio_analyser"></canvas>
        <!-- 녹음 완료 -->
        <div class="recording_progress" v-if="recordingStatus === 3">
          <div class="recording_progress_current" :style="{ width: `${progressBarPercent}%` }"></div>
          <div class="recording_progress_dot" :style="{ left: `${progressBarPercent > 2 ? progressBarPercent - 2 : progressBarPercent}%` }"></div>
          <div class="recording_progress_time flex_space_between" v-if="audioDuration && audioDuration !== Infinity">
            <span class="recording_progress_time_text">{{ currentTimeHHMM }}</span>
            <span class="recording_progress_time_text">{{ durationMMHH }}</span>
          </div>
        </div>
      </div>
      <!-- 녹음 전, 녹음 중, 녹음 완료-->
      <div class="recorder_area flex_justify_center align_center" v-if="recordingStatus === 1 || recordingStatus === 2 || recordingStatus === 3">
        <div class="recorder_circle" v-if="recordingStatus === 1 || recordingStatus === 2">
          <!-- 녹음 전  -->
          <button class="recorder_ready_btn" v-if="recordingStatus === 1" @click="startVoiceRecord">
            <img class="recorder_ready_btn_img" src="/media/img/voice_recorder_img.png" alt="녹음" />
          </button>
          <!-- 녹음 중  -->
          <button class="recorder_recording_btn" v-else-if="recordingStatus === 2" @click="onClickStopVoiceRecord(3)">
            <img class="recorder_recording_btn_img" src="/media/img/voice_recording_img.png" alt="녹음" />
          </button>
        </div>

        <!-- 녹음 완료 -->
        <div class="recording_btn_group flex_space_between" v-if="recordingStatus === 3">
          <button class="recording_stop_btn flex_justify_center align_center" @click="onClickVoicePreveiw">
            <img :src="voiceRecorderIsPlay ? '/media/img/voice_recording_img.png' : '/media/img/recording_btn.png'" class="recording_stop_btn_img" :class="{ pause: voiceRecorderIsPlay }" alt="중지" />
          </button>
          <button class="recording_replay_btn flex_justify_center align_center" @click="onClickBackStep1">
            <img src="/media/img/replay_btn.png" class="recording_replay_btn_img" alt="중지" />
          </button>
        </div>
      </div>
      <p class="recorder_count" v-if="recordingStatus === 2">
        {{ getRecorderCount }}
      </p>
      <!-- 녹음 완료, 파일명 입력 -->
      <div class="filename_wrap" v-if="recordingStatus === 4">
        <h3 class="filename_title">파일명을 설정해주세요</h3>
        <div class="w_90 input_wrap">
          <input class="w_100 mlr_auto filename_input" size="10" type="text" id="fileName" @input="onInputTextFileName" placeholder="파일명을 10자 내로 입력해주세요" />
          <img v-if="fileName.length > 0" class="input_delete_img cursor" src="/media/images/icon_close_24.svg" @click="onClickFileNameDelete" alt="닫기" />
        </div>
        <p v-if="isWarn" class="w_90 text_warn">파일명은 띄어쓰기 포함 10글자까지만 가능합니다.</p>
      </div>
      <button
        v-if="recordingStatus === 3 || recordingStatus === 4"
        :disabled="(fileName.length === 0 || fileName.length > 10) && recordingStatus === 4"
        class="recroding_complet_btn"
        @click="onClickUploadVoiceFile">
        {{ recordingCompleteBtn }}
      </button>
    </div>
  </div>
</template>
<script>
import Confirm from '../common/Confirm.vue';
import Alert from '../common/Alert.vue';
import { uploadChosenFileApi, registMySoundByFileApi } from '@/service/api/brandApi';
export default {
  name: 'VoiceRecording',
  props: {
    brandid: {
      type: String,
      required: false,
      defalt: null,
    },
  },
  emits: ['close-modal'],
  data() {
    return {
      audioChuncks: [],
      mp3Blob: null,
      recordingStatus: 1,
      barLength: 55,
      voiceRecorderIsPlay: false,
      progressBarPercent: 0,
      currentTime: 0,
      fileResult: {
        fileOnServer: null,
        playtime: -1,
      },
      audioDuration: 10,
      fileName: '',
      audioRecorder: null,
      audioStream: null,
      recorderInterval: -1,
      recorderCount: 0,
      now: -1,
      timeOffset: 70,
      refId: -1,
      canvasObj: {},
      audioEle: null,
      isBackConfirmModal: false,
      isApiCall: false,
      isAlert: false,
      alertText: '',
      isSuccessRegistMySound: false,
      isWarn: false,
    };
  },
  components: {
    Confirm,
    Alert,
  },
  mounted() {
    this.audioEle = this.getAudioEle();
    this.canvasObj.bars = [];
    if (this.audioEle) {
      this.audioEle.addEventListener('timeupdate', this.onVoiceRecorderTimeUpdate);
      this.audioEle.addEventListener('ended', this.onVoiceRecorderEnded);
    }
  },
  methods: {
    /**
     * @description 뒤로가기
     * @author CHOI DAE GEON
     */
    onClickBackStep() {
      if (this.recordingStatus === 1) {
        this.$emit('close-modal');
      } else if (this.recordingStatus === 2) {
        this.onClickStopVoiceRecord(1);
        this.audioChuncks = [];
      } else if (this.recordingStatus === 3) {
        this.audioChuncks = [];
        this.audioEle.src = null;
        this.recordingStatus = 1;
      } else if (this.recordingStatus === 4) {
        this.audioChuncks = [];
        this.recordingStatus = 3;
      }
    },
    /**
     * @description 모달종료
     * @author CHOI DAE GEON
     */
    onClickClose() {
      this.$emit('close-modal');
    },
    /**
     * @description 오디오 Element
     * @author CHOI DAE GEON
     */
    getAudioEle() {
      return document.querySelector('#voideReocrderAudio');
    },
    /**
     * @description 목소리 녹음 시작
     * @author CHOI DAE GEON
     */
    startVoiceRecord() {
      if (navigator.mediaDevices) {
        navigator.mediaDevices
          .getUserMedia({ audio: true })
          .then(stream => {
            this.audioStream = stream;
            this.audioRecorder = new MediaRecorder(stream);
            this.audioRecorder.addEventListener('dataavailable', this.onDataAvailable);

            this.audioRecorder.start();

            this.recorderInterval = setInterval(() => {
              if (this.recorderCount < 60) {
                this.recorderCount += 1;
              } else {
                //
                this.clearCanvas();
                this.recordingStatus = 3;
                this.audioStream.getTracks().forEach(track => track.stop());
                this.audioDuration = this.recorderCount;
                clearInterval(this.recorderInterval);
              }
            }, 1000);
            this.recordingStatus = 2;

            this.$nextTick(async () => {
              await this.$nextTick();
              this.now = parseInt(performance.now()) / this.timeOffset;

              // canvas init
              this.canvasInit();
              this.canvasObj.ctx.beginPath();

              // canvan loop
              this.canvasLoop();
            });
          })
          .catch(err => {
            console.log('Error accessing the microphone: ' + err);
          });
      }
    },
    /**
     * @description canvas init
     * @author CHOI DAE GEON
     */
    canvasInit() {
      const voicerecordingProgress = document.querySelector('.voicerecording_progress');
      const width = voicerecordingProgress.clientWidth;
      const height = voicerecordingProgress.clientHeight;

      this.canvasObj.canvas = document.querySelector('#audioAnalyser');
      this.canvasObj.ctx = this.canvasObj.canvas.getContext('2d');
      this.canvasObj.width = width;
      this.canvasObj.height = height;

      this.canvasObj.canvas.width = this.canvasObj.width * window.devicePixelRatio;
      this.canvasObj.canvas.height = this.canvasObj.height * window.devicePixelRatio;
      this.canvasObj.canvas.style.width = this.canvasObj.width + 'px';
      this.canvasObj.canvas.style.height = this.canvasObj.height + 'px';
      this.canvasObj.ctx.scale(window.devicePixelRatio, window.devicePixelRatio);
    },
    /**
     * @description canvas loop
     * @author CHOI DAE GEON
     */
    canvasLoop() {
      this.canvasObj.ctx.clearRect(0, 0, this.canvasObj.canvas.width, this.canvasObj.canvas.height);

      if (parseInt(performance.now() / this.timeOffset) > this.now) {
        this.now = parseInt(performance.now() / this.timeOffset);

        const freq = Math.floor(Math.random() * 64) + 16;
        this.canvasObj.bars.push({
          x: this.canvasObj.width,
          y: this.canvasObj.height / 2 - freq / 2,
          height: freq,
          width: 3,
        });
      }

      this.canvansDraw();
      this.refId = requestAnimationFrame(this.canvasLoop);
    },
    /**
     * @description canvas draw
     * @author CHOI DAE GEON
     */
    canvansDraw() {
      for (let i = 0; i < this.canvasObj.bars.length; i++) {
        const bar = this.canvasObj.bars[i];
        this.canvasObj.ctx.fillStyle = `rgba(255, 45, 85, 1)`;
        this.canvasObj.ctx.fillRect(bar.x, bar.y, bar.width, bar.height);
        bar.x = bar.x - 2;

        if (bar.x < 1) {
          this.canvasObj.bars.splice(i, 1);
        }
      }
    },
    /**
     * @description Clear Canvas
     * @author CHOI DAE GEON
     */
    clearCanvas() {
      this.canvasObj.bars = [];
      this.canvasObj.ctx.clearRect(0, 0, this.canvasObj.canvas.width, this.canvasObj.canvas.height);
      this.canvasObj.ctx.beginPath();
      cancelAnimationFrame(this.refId);
      this.now = -1;
    },
    /**
     * @description 목소리 녹음 중지
     * @author CHOI DAE GEON
     */
    onClickStopVoiceRecord(step = 3) {
      this.audioStream.getTracks().forEach(track => track.stop());
      this.audioDuration = this.recorderCount;
      clearInterval(this.recorderInterval);
      this.recorderCount = 0;

      if (this.recordingStatus === 2) {
        this.clearCanvas();
      }
      this.recordingStatus = step;
    },
    /**
     * @description Data Available Event
     * @param event
     */
    onDataAvailable(event) {
      this.audioChuncks.push(event.data);
      const blob = new Blob(this.audioChuncks, { type: 'audio/mp3' });
      this.mp3Blob = blob;
      const audioEle = this.getAudioEle();
      const audioUrl = URL.createObjectURL(blob);
      audioEle.src = audioUrl;
    },
    /**
     * @description 미리듣기
     * @author CHOI DAE GEON
     */
    onClickVoicePreveiw() {
      const audioEle = this.getAudioEle();
      if (audioEle.paused) {
        this.currentTime = 0;
        audioEle
          .play()
          .then(() => {
            this.voiceRecorderIsPlay = true;
          })
          .catch(() => {
            this.voiceRecorderIsPlay = false;
          });
      } else {
        audioEle.pause();
        this.voiceRecorderIsPlay = false;
      }
    },
    /**
     * @description 1단계로 이동
     * @author CHOI DAE GEON
     */
    onClickBackStep1() {
      this.isBackConfirmModal = true;
    },
    /**
     * @description 파일명 input event
     * @param $event
     * @author CHOI DAE GEON
     */
    onInputTextFileName($event) {
      if ($event.target) {
        this.fileName = $event.target.value;
        if (this.fileName.length > 10) {
          this.isWarn = true;
          $event.target.value = $event.target.value.slice(0, 10);
          this.fileName = $event.target.value.slice(0, 10);
          setTimeout(() => {
            this.isWarn = false;
          }, 1000);
        }
      }
    },
    /**
     * @description 파일명 삭제
     * @author CHOI DAE GEON
     */
    onClickFileNameDelete() {
      const fileNameEle = document.querySelector('#fileName');
      if (fileNameEle) {
        fileNameEle.value = '';
        this.fileName = '';
      }
    },
    /**
     * @description 파일 업로드
     * @author CHOI DAE GEON
     */
    async onClickUploadVoiceFile() {
      if (!this.isApiCall) {
        this.isApiCall = true;
        if (this.recordingStatus === 3) {
          const formData = new FormData();
          formData.append('corpId', this.$cookies.get('corpId'));
          formData.append('brandId', this.$cookies.get('brandId'));
          formData.append('fileToUpload', this.mp3Blob);
          try {
            const { data, status } = await uploadChosenFileApi(formData);
            if (status === 200) {
              this.audioChuncks = [];
              this.recordingStatus = 4;
              const { result } = data;
              const { fileOnServer, tempNo } = result;
              this.fileResult.fileOnServer = fileOnServer;
              this.fileResult.playtime = this.audioDuration;
              this.fileResult.tempNo = tempNo;
            }
          } catch (error) {
            if (error.response) {
              const { status, data } = error.response;
              if (status === 500 || status === 400) {
                console.log('uploadChosenFileApi error : ', error);
                const { resultMsg } = data;
                this.alertText = resultMsg;
                this.isAlert = true;
              }
            }
          } finally {
            this.isApiCall = false;
            this.audioEle.pause();
            this.audioEle.src = null;
            this.voiceRecorderIsPlay = false;
          }
        } else if (this.recordingStatus === 4) {
          try {
            const { status } = await registMySoundByFileApi(this.$cookies.get('corpId'), this.brandid, this.fileName, this.fileResult.tempNo, this.fileResult.playtime, '2');
            if (status === 200) {
              this.alertText = `파일이 등록되었습니다.`;
              this.isAlert = true;
              this.isSuccessRegistMySound = true;
            }
          } catch (error) {
            this.isSuccessRegistMySound = false;
            if (error.response) {
              const { status, data } = error.response;
              if (status === 500 || status === 400) {
                console.log('uploadChosenFileApi error : ', error);
                const { resultMsg } = data;
                this.alertText = resultMsg;
                this.isAlert = true;
              }
            }
          } finally {
            this.isApiCall = false;
          }
        }
      }
    },
    /**
     * @description timeupdate event
     * @author CHOI DAE GEON
     */
    onVoiceRecorderTimeUpdate($event) {
      if ($event) {
        this.currentTime = Math.floor($event.target.currentTime);
        if ($event.target.duration !== Infinity && !isNaN($event.target.duration)) {
          this.audioDuration = Math.floor($event.target.duration);
        }

        this.progressBarPercent = (this.currentTime / this.audioDuration) * 100;
      }
    },
    /**
     * @description ended event
     * @author CHOI DAE GEON
     */
    onVoiceRecorderEnded() {
      this.voiceRecorderIsPlay = false;
    },
    /**
     * @description 녹음완료 후 뒤로가기 Confrim Modal Confirm
     * @author CHOI DAE GEON
     */
    onClickBackConfirmModalConfirm() {
      this.clearCanvas();
      this.recordingStatus = 1;
      this.voiceRecorderIsPlay = false;
      this.audioDuration = 0;
      this.audioChuncks = [];
      this.audioEle.pause();
      this.recorderCount = 0;

      this.now = -1;
      this.isBackConfirmModal = false;
    },
    /**
     * @description 녹음완료 후 뒤로가기 Confrim Modal Cancel
     * @author CHOI DAE GEON
     */
    onClickBackConfirmModalCancel() {
      this.isBackConfirmModal = false;
    },
    /**
     * @description Alert Close
     * @author CHOI DAE GEON
     */
    onCloseAlert() {
      this.isAlert = false;
      if (this.isSuccessRegistMySound) {
        this.onClickClose();
      }
    },
  },
  computed: {
    getRecorderCount() {
      const hh = Math.floor(this.recorderCount / 60) > 9 ? Math.floor(this.recorderCount / 60) : `0${Math.floor(this.recorderCount / 60)}`;
      const mm = Math.floor(this.recorderCount % 60) > 9 ? Math.floor(this.recorderCount % 60) : `0${Math.floor(this.recorderCount % 60)}`;
      return `${hh}:${mm}`;
    },
    currentTimeHHMM() {
      if (this.currentTime) {
        const hh = Math.floor(this.currentTime / 60) > 9 ? Math.floor(this.currentTime / 60) : `0${Math.floor(this.currentTime / 60)}`;
        const mm = Math.floor(this.currentTime % 60) > 9 ? Math.floor(this.currentTime % 60) : `0${Math.floor(this.currentTime % 60)}`;

        return `${hh}:${mm}`;
      }
      return '00:00';
    },
    durationMMHH() {
      if (this.audioDuration) {
        const hh = Math.floor(this.audioDuration / 60) > 9 ? Math.floor(this.audioDuration / 60) : `0${Math.floor(this.audioDuration / 60)}`;
        const mm = Math.floor(this.audioDuration % 60) > 9 ? Math.floor(this.audioDuration % 60) : `0${Math.floor(this.audioDuration % 60)}`;
        return `${hh}:${mm}`;
      }
      return '01:00';
    },
    recorderText() {
      if (this.recordingStatus === 1) {
        return `음성 녹음을 시작해주세요.`;
      } else if (this.recordingStatus === 2) {
        return `음성 녹음이 진행중입니다.`;
      } else if (this.recordingStatus === 3) {
        return `음성 녹음이 완료되었습니다.`;
      }

      return '';
    },
    recordingCompleteBtn() {
      if (this.recordingStatus === 3) {
        return '다음';
      }

      return '완료';
    },
  },
  destroyed() {
    if (this.audioRecorder) {
      this.audioRecorder.removeEventListener('dataavailable', this.onDataAvailable);
    }
    this.audioEle = this.getAudioEle();
    if (this.audioEle) {
      this.audioEle.removeEventListener('timeupdate', this.onVoiceRecorderTimeUpdate);
    }

    if (this.audioStream) {
      this.audioStream.getTracks().forEach(track => track.stop());
    }
  },
};
</script>
<style scoped>
.modal .voicerecording_box {
  width: 540px;
  background: linear-gradient(0deg, #151515, #151515), linear-gradient(0deg, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.08));
  padding: 0;
  height: 780px;
}
.voicerecording_title_wrap {
  margin: 32px 45px 0 45px;
}
.voicerecording_title {
  color: var(--white);
  font-size: 24px;
  line-height: 34px;
  font-weight: 700;
}

.less_than {
  display: inline-block;
  margin-right: 17px;
  width: 10px;
  height: 18px;
}

.voicerecording_desc {
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  margin-top: 63px;
  color: var(--white);
  text-align: center;
}

.voicerecording_desc_sub {
  font-size: 18px;
  font-weight: 400;
  color: transparent;
}
.voicerecording_desc_sub.ready {
  color: var(--white);
}
.recorder_area {
  margin-top: 53px;
}
.recorder_circle {
  width: 136px;
  height: 136px;
  border: 3px solid #ffffff33;
  border-radius: 50%;
  position: relative;
}

.recorder_ready_btn,
.recorder_recording_btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100px;
  height: 100px;
  background-color: #fd4e6d;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: none;
}

.recorder_recording_btn {
  background-color: #ffffff14;
}

.modal_box .recorder_recording_btn:hover,
.modal_box .recorder_recording_btn:focus {
  background-color: #ffffff14;
}

.recorder_recording_btn_img {
  width: 32px;
  height: 32px;
}

.recorder_count,
.recorder_count_duration {
  font-size: 20px;
  text-align: center;
  font-weight: 400;
  color: var(--white);
  line-height: 28px;
  margin-top: 18px;
  margin-bottom: 91px;
}
.recorder_ready_btn:hover {
  background-color: #fd4e6d;
  opacity: 1;
}

.recorder_ready_btn_img {
  width: 32px;
  height: 46px;
}

.audio_analyser {
  bottom: 0;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
}
.text_warn {
  margin: 5px auto;
  text-align: start;
  font-size: 14px;
  font-weight: 400;
  line-height: 20.27px;
  color: #ff1150;
  padding-left: 24px;
}
.voicerecording_progress {
  background-color: #ffffff0a;
  margin-top: 23px;
  height: 189px;
  position: relative;
  overflow: hidden;
}
.voicerecording_before {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
}

.recording_bar {
  width: 2px;
  height: 9px;
  background-color: #ffffffcc;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
}

.recording_progress {
  max-width: 502px;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 5px;
  background-color: #ffffff99;
  border-radius: 4px;
}

.recording_progress_current {
  width: 0%;
  height: 5px;
  background-color: #ffffff99;
  border-radius: 4px;
  position: absolute;
  top: 0;
  left: 0;
  transition: all linear 0.3s;
}
.recording_progress_dot {
  width: 14px;
  height: 14px;
  position: absolute;
  background-color: var(--white);
  border-radius: 50%;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  transition: all linear 0.3s;
}
.recording_progress_time {
  position: absolute;
  top: 21px;
  left: 0;
  width: 100%;
}

.recording_progress_time_text {
  font-size: 18px;
  font-weight: 400;
  color: #ffffffcc;
  line-height: 26px;
}

.recording_btn_group {
  max-width: 164px;
  width: 100%;
  margin: 0 auto;
  margin-top: 108px;
}

.recording_stop_btn,
.recording_replay_btn {
  width: 62px;
  height: 62px;
  border-radius: 50%;
  border: 1px solid #ffffff99;
  background-color: #151515;
}

.modal_box .recording_stop_btn:hover,
.modal_box .recording_stop_btn:focus,
.modal_box .recording_replay_btn:hover,
.modal_box .recording_replay_btn:focus {
  background-color: #151515;
}
.recording_stop_btn_img {
  width: 16px;
  height: 19px;
}

.recording_stop_btn_img.pause {
  height: 16px;
}

.recording_replay_btn_img {
  width: 24px;
  height: 21px;
}

.filename_wrap {
  margin-top: 60px;
}

.filename_title {
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  font-weight: 500;
  color: var(--white);
}
.input_wrap {
  position: relative;
  margin: 0 auto;
  margin-top: 24px;
}
.input_delete_img {
  position: absolute;
  right: 22px;
  top: 50%;
  transform: translateY(-50%);
}

.filename_input {
  display: inherit;
  padding-right: 54px;
  position: relative;
  border: 1px solid #ffffff4d;
}
.filename_input:focus {
  border: 1px solid transparent;
  box-shadow: 0 0 0 1px #ff2d55;
  box-sizing: border-box;
}

.recroding_complet_btn {
  position: absolute;
  bottom: 32px;
  right: 32px;
}

.recroding_complet_btn:disabled {
  opacity: 0.3;
}

@media all and (max-width: 1536px) {
  .modal .voicerecording_box {
    max-width: 432px;
    width: 100%;
    height: 624px;
  }
  .voicerecording_title_wrap {
    margin: 25px 36px 0 25px;
  }
  .voicerecording_title {
    font-size: 19px;
    line-height: 27px;
  }
  .voicerecording_desc {
    font-size: 16px;
    line-height: 22px;
    margin-top: 50px;
  }
  .voicerecording_desc_sub {
    font-size: 14px;
  }

  .voicerecording_progress {
    margin-top: calc(23px * 0.8);
    height: calc(189px * 0.8);
  }

  .recorder_area {
    margin-top: calc(53px * 0.8);
  }

  .recorder_circle {
    width: calc(136px * 0.8);
    height: calc(136px * 0.8);
  }

  .recorder_ready_btn,
  .recorder_recording_btn {
    width: calc(100px * 0.8);
    height: calc(100px * 0.8);
  }

  .recorder_ready_btn_img {
    width: calc(32px * 0.8);
    height: calc(46px * 0.8);
  }

  .less_than {
    margin-right: 13px;
    width: 8px;
    height: 14px;
  }

  .recorder_recording_btn_img {
    width: calc(32px * 0.8);
    height: calc(32px * 0.8);
  }

  .recorder_count,
  .recorder_count_duration {
    font-size: calc(20px * 0.8);
    line-height: calc(28px * 0.8);
    margin-top: calc(18px * 0.8);
    margin-bottom: 0;
  }

  .recording_btn_group {
    margin-top: 0;
  }

  .recroding_complet_btn {
    bottom: 16px;
    right: 16px;
  }

  .recording_progress {
    width: 92%;
  }
  .recording_progress_time {
    top: calc(21px * 0.8);
  }

  .filename_wrap {
    margin-top: calc(60px * 0.8);
  }

  .filename_title {
    font-size: calc(20px * 0.8);
    line-height: calc(28px * 0.8);
  }

  .text_warn {
    margin: 4px 19.2px;
    font-size: 11.2px;
    line-height: 16.2px;
    padding-left: 19.2px;
  }
}
</style>
