<template>
  <div class="alert" :class="this.$store.getters['simplealert/getIsShow'] ? 'alert_show' : 'alert_hide'">
    <div class="single-file-box alert_box">
      <h3 v-html="this.$store.getters['simplealert/getBodyText']"></h3>
      <div v-if="this.$store.getters['simplealert/getIsYn']" class="single-file-check">
        <span @click="close()">아니오</span>
        <span class="red_text" @click="yesClose()">네</span>
      </div>
      <div v-else class="single-file-check">
        <span class="red_text" @click="close()">확인</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SimpleAlert',
  props: {
    alertText: {
      type: String,
      required: false,
      default: '',
    },
  },
  mounted() {
    // console.log(this.$store.getters['simplealert/getIsShow']);
  },

  methods: {
    close() {
      this.$store.commit('simplealert/close');
    },
    yesClose() {
      this.$store.commit('simplealert/setIsFunc');
      setTimeout(() => {
        this.$store.commit('simplealert/close');
      }, 300);
    },
  },
};
</script>

<style scoped>
@media all and (max-width: 500px) {
  .single-file-check p {
    font-size: 15px !important;
    color: #ff2d55;
    font-weight: 400;
  }
}
.alert {
  position: relative;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
}
.alert_box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 450px;
  border-radius: 16px;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.08)), #151515;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.8);
  padding-top: 32px;
  text-align: Center;
}
.single-file-box {
  box-shadow: 0px 10px 20px rgb(0 0 0 / 80%);
}
.single-file-box h3 {
  padding-bottom: 32px;
  color: rgba(255, 255, 255, 0.87);
  font-size: 16px;
  font-weight: 300;
  text-align: center;
  line-height: 1.3;
  word-break: keep-all;
}
.single-file-check {
  display: table;
  width: 100%;
}
.single-file-check span {
  display: table-cell;
  font-size: 15px !important;
  vertical-align: middle;
  width: 50%;
  height: 60px;
  border-top: 1px solid rgba(255, 255, 255, 0.08);
  align-items: center;
  color: var(--FFFFFFDE);
  cursor: pointer;
}

.single-file-check .red_text {
  color: var(--FFFFFFDE);
  color: #ff2d55;
}
.alert_show {
  display: block !important;
}
.alert_hide {
  display: none !important;
}
</style>
