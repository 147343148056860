import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import VueCookies from 'vue-cookies';
import { TimePicker } from 'vue-drumroll-datetime-picker';
import 'vue-drumroll-datetime-picker/dist/style.css';
import './assets/sass/vue-drumroll-datetime-picker.sass';
import './assets/css/common/_variable.css';
Vue.component('TimePicker', TimePicker);

Vue.use(VueCookies);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
