<template>
  <div class="ad_music_alert alert">
    <div class="ad_music_alert_box alert_box">
      <h3 v-html="contents"></h3>
      <div class="ad_music_alert_btn">
        <p @click="onClickCancel">아니오</p>
        <button @click="onClickConfirm" class="delete-music">네</button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Confirm',
  props: {
    contents: {
      type: String,
      default: '',
      required: false,
    },
  },
  emits: ['cancel-click', 'confirm-click'],
  methods: {
    onClickCancel() {
      this.$emit('cancel-click');
    },
    onClickConfirm() {
      this.$emit('confirm-click');
    },
  },
};
</script>
<style src="@/assets/css/font.css"></style>
<style src="@/assets/css/content.css"></style>
<style src="@/assets/css/musicManagement.css"></style>
